.cCouponPage
    width: 100svw
    padding: 0
    font-size: 1svw
    .background
        position: fixed
        z-index: 0
        top: 50%
        left: 0
        min-width: 100%
        min-height: 100svh
        object-fit: cover
        object-position: center
        transform: translateY(-50%)
    .top-bar
        width: 100svw
        height: 30.312em
        background-color: white
        .title
            padding: 7.5em 3em
            span
                font-size: 4.687em
                font-weight: bold
                color: black
        .button-list
            width: 100%
            height: 8.268em
            padding: 0
            button
                width: 50%
                height: 100%
                &.active
                    border-bottom: 0.468em solid black
                span
                    font-size: 3.9em
                    font-weight: bold
                    color: black
    .coupon-container
        width: 100svw
        height: calc(100svh - 30.312em - 4rem)
        overflow: auto
    .coupon-state
        position: sticky
        z-index: 1
        top: 0
        left: 0
        width: 100%
        height: 10.5em
        display: flex
        justify-content: space-around
        padding: 4em 3em 0
        background-color: #00000099
        padding-bottom: 10em
        button
            width: 29.21em
            height: 6.5em
            border-radius: 0.781em
            background-color: transparent
            color: white
            &.active
                background-color: white
                color: black
            span
                font-size: 3.9em
                font-weight: 500
    .coupon-list
        position: absolute
        top: 0
        left: 0
        width: 100%
        padding: 16em 3em 10em

    /* Coupon Component */
    .cCoupon
        position: relative
        display: flex
        width: 93.75em
        height: 32.34em
        margin-bottom: 3em
        .coupon-background
            position: absolute
            top: 0
            left: 0
            width: 100%
        .coupon-content
            display: flex
            width: 100%
            padding-left: 27.5em
            margin-bottom: 2.5em
            .description
                position: relative
                width: 73%
                height: 100%
                display: flex
                flex-direction: column
                padding: 5.7em 4.6em
                line-height: 1
                .name
                    font-size: 3.75em
                    font-weight: bold
                    margin-bottom: 0.8em
                .title
                    font-size: 3.281em
                    font-weight: 500
                    padding-bottom: 0.8em
                .date
                    font-size: 2.812em
                    font-weight: 500
                    margin-top: auto
            .instruction-buttons
                display: flex
                flex-direction: column
                flex: 1
                width: 27%
                height: 100%
                cursor: pointer
                .instruction-button
                    flex: 1
        .expired-overlay
            position: absolute
            width: 100%
            height: 100%
            background: center / 98.5% no-repeat
            z-index: 10
            display: flex
            justify-content: center
            align-items: center
            color: #fff
            font-size: 7em

    /* Coupon Instruction */
    .coupon-instruction-popup
        position: relative
        width: 100em
        height: 150em
        display: flex
        flex-direction: column
        overscroll-behavior: contain
        padding-bottom: 4rem
        background-color: white
        border-radius: 3.125em 3.125em 0 0
        overflow: hidden
        .top-bar
            width: 100%
            height: 13.65em
            display: flex
            align-items: center
            border-bottom: 0.32em solid #D3D3D3
            padding: 3em 2.5em 0
            .tier-icon
                width: 7.8em
                height: calc(7.8em * 7 / 8)
            label
                position: absolute
                left: 50%
                transform: translate(-50%)
                color: black
                font-size: 4.687em
                font-weight: bold
                line-height: 1
            .close-btn
                width: 5.1em
                height: 5.1em
                padding: 1em
                margin-top: -1em
                margin-left: auto
                margin-right: 0.4em
        .content-container
            position: relative
            flex: 1
            min-height: calc(150em - 13.65em - 4rem)
            .content
                display: flex
                flex-direction: column
                width: 100%
                height: 100%
                overflow: auto
                padding: 5.6em 3em
                line-height: 1.5
                .section
                    display: flex
                    flex-direction: column
                    margin-bottom: 10em
                label
                    font-size: 2.812em
                    font-weight: 500
                    color: black
                    white-space: pre-wrap
            .shadow
                position: absolute
                width: 100%
                height: 15px
                bottom: 0
                box-shadow: inset 0 -10px 15px -10px rgba(0, 0, 0, 0.25)
            img
                padding: 0 2.5em
                padding-bottom: 2.5em