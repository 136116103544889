.cRedeemPage
    .swiper
        width: 100%
    .swiper-slide
        padding-top: 1vw
        padding-bottom: 1vw
        text-align: center
        background: #000
        color: #fff
        width: 100vw
        height: 100%
        display: flex
        justify-content: center
        align-items: center

    .swiper-slide 
        img
        display: block
        width: 100%
        height: 100%
        object-fit: cover

    .triangle-down
        width: 0
        height: 0
        border-left: 4px solid transparent
        border-right: 4px solid transparent
        border-top: 6px solid #666666
        margin: 0 1vw

/* PointsOverview Component */
.point-overview
    display: flex
    flex-direction: column
    align-items: center
    background-size: cover
    font-size: 1vw
    width: 100em
    height: 46.875em
    padding-bottom: 3.3em
    .overview-container
        width: 100%
        padding: 8.4em 3.5em 5em
    .background
        position: absolute
        top: 0
        left: 0
        width: 100%
.user-profile
    display: flex
    width: 100%
    min-width: 0
    font-size: 1em
    font-weight: bold
    align-items: flex-end
    line-height: 1
    .vip-icon
        width: 6em
        height: calc(6em * 7 / 8)
        object-fit: cover
        text-align: center
        margin-right: 2.5em
    label
        font-size: 3.9em
        font-weight: bold
        color: white
    .tier-name
        margin-right: 0.55em
    .member-benefits
        display: flex
        font-size: 3.9em
        font-weight: 500
        color: white
        padding-bottom: 3px
        margin-left: auto
        .under-line:after
            content: ""
            position: absolute
            bottom: -3px
            left: 0
            display: block
            width: 100%
            height: 1px
            background: white
.progress
    width: 100%
    margin: 3.5em 0 4.5em
.points-info
    width: 100%
    height: 8em
    display: flex
    align-items: flex-end
    color: white
    flex-wrap: wrap
    row-gap: 2em
    column-gap: 1em
    .label
        font-size: 4.687em
        font-weight: bold
        line-height: 1
    .points
        font-size: 8.12em
        font-weight: bold
        line-height: 0.85
    .expire
        font-size: 3.12em
        font-weight: 500
        margin-left: auto
        line-height: 1
.button-list
    width: 100%
    display: flex
    padding: 0 2em
    button
        width: 50%
        height: 8.75em
        &:not(:last-child):after
            content: ""
            position: absolute
            top: 0
            right: 0
            width: 0.23em
            height: 100%
            background: white
        span
            font-size: 3.9em
            font-weight: 500
            color: white
