.tabActive {
  @apply after:absolute after:top-full after:w-full after:border-b-2 after:border-black text-black after:mt-2;
}

// 排序icon
.sortAsc,.sortDesc {
  @apply after:absolute after:left-[4px] after:h-3 after:w-3 after:bg-white after:opacity-70;
}

// 升冪
.sortAsc {
  @apply  after:bottom-0;
}

// 降冪
.sortDesc {
  @apply after:top-0;
}