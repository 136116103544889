// 產品列表禁點遮罩
.mask {
  @apply after:absolute after:z-0 after:top-0 after:h-full after:w-full after:bg-[#F4F4F4] after:opacity-50;
}

// 排序icon
.sortAsc,.sortDesc {
  @apply after:absolute after:left-[4px] after:h-3 after:w-3 after:bg-white after:opacity-70;
}

// 升冪
.sortAsc {
  @apply  after:top-0;
}

// 降冪
.sortDesc {
  @apply after:bottom-0;
}