.popup-overlay
    position: fixed
    top: 0
    left: 0
    z-index: 1000
    background-color: rgba(0, 0, 0, 0.5)
    width: 100svw
    height: 100svh
    display: flex
    justify-content: center
    align-items: center
    opacity: 0
    font-size: 1vw
    &.mask-invisible
        background-color: transparent
    &.clickable
        pointer-events: none
    &.slide
        align-items: flex-end
    @media screen and (min-width: 600px)
        font-size: 6.00px

.popup-content
    opacity: 0
    pointer-events: auto

@keyframes fadeIn
    from 
        opacity: 0
        transform: scale(0.8)
    to 
        opacity: 1
        transform: scale(1)

@keyframes fadeOut
    from 
        opacity: 1
        transform: scale(1)
    to 
        opacity: 0
        transform: scale(0.8)

.popup-content.fade.enter
    animation: fadeIn 0.5s forwards

.popup-content.fade.exit
    animation: fadeOut 0.5s forwards

@keyframes slideInUp
    from
        transform: translateY(100%)
        opacity: 0
    to
        transform: translateY(0)
        opacity: 1

@keyframes slideOutDown
    from
        transform: translateY(0)
        opacity: 1
    to
        transform: translateY(100%)
        opacity: 0

.popup-content.slide.enter
    animation: slideInUp 0.5s forwards

.popup-content.slide.exit
    animation: slideOutDown 0.5s forwards

@keyframes overlayFadeIn
    from 
        opacity: 0
    to 
        opacity: 1

@keyframes overlayFadeOut
    from 
        opacity: 1
    to 
        opacity: 0

.popup-overlay.enter
    animation: overlayFadeIn 0.5s forwards

.popup-overlay.exit
    animation: overlayFadeOut 0.5s forwards
