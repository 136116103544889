.cTopMemberInfoBox
	position: relative
	width: 100%
	font-size: 1svw
	padding: 2.5em 3.2em
	.comp-user-avatar
		user-select: none
		overflow: hidden
		border-radius: 50%
		width: 13.75em
		height: 13.75em
		margin: 0.3em 2.6em 0.3em 0
		outline: 0.65em solid black
		img
			height: 100%
			width: 100%
			object-fit: cover
			text-align: center
			color: transparent
			border-radius: 50%
	.pass-logo
		position: absolute
		z-index: 1
		top: 0
		right: 0
		width: 26.5em
		margin: 4.5em 3.2em
	.profile-info
		display: flex
		align-items: flex-end
		width: 100%
		margin-top: 3em
		img
			width: 6em
			height: calc(6em * 7 / 8)
			object-fit: cover
			text-align: center
			margin-right: 3em
		label
			font-size: 3.9em
			font-weight: bold
			color: white
			margin-right: 0.382em
			line-height: 1
		span
			font-size: 3em
			font-weight: 400
			color: #767676
			line-height: 1
		button
			display: flex
			justify-content: center
			align-items: center
			width: 23em
			height: 5.78em
			background: white
			border-radius: 1.05em
			margin-top: -0.5em
			margin-left: auto
			span
				color: black
				font-size: 3.125em
				font-weight: 500
				line-height: 1
	.progress-bar
		position: relative
		width: 100%
		margin-top: 2.2em
	.member-tier-info
		display: flex
		white-space: nowrap
		text-overflow: ellipsis
		text-align: center
		margin-top: 4.2em
		flex-wrap: wrap
		row-gap: 2em
		.info-message
			font-size: 3.125em
			font-weight: 500
			color: white
			line-height: 1
		.member-point
			display: flex
			margin-left: auto
			margin-top: -1em
			img
				width: 3.9em
				height: 3.9em
				object-fit: cover
				text-align: center
				margin-right: 1.5em
			label
				font-size: 3.9em
				font-weight: 500
				color: white
				line-height: 1