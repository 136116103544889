@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/scss/image-gallery.scss";

* {
	@apply relative;
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../assets/fonts/HelveticaNeueLTStd-Bd.otf') format('opentype');
	font-weight: 600;
}

body {
	@apply bg-white font-normal leading-normal text-blackT80;
  touch-action: manipulation;
}

@layer base {
	html {
		font-family: "Noto Sans", "-apple-system", "BlinkMacSystemFont",
			'"Microsoft JhengHei UI"', '"Microsoft JhengHei"', '"Segoe UI"', "Roboto",
			'"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"',
			'"Segoe UI Emoji"', '"Segoe UI Symbol"';
		@apply antialiased;
	}
}

.App {
	height: 100svh;
	overflow: auto;
	.upgrade-popup {
		position: relative;
		width: 80em;
		height: calc( 80em * 690 / 450 );
    box-shadow: 0.6em 0.6em 1.21em rgba(0, 0, 0, 0.25);
		overscroll-behavior: contain;
    border-radius: 0.11em;
    overflow: hidden;
		img {
			width: 100%;
		}
		button {
			width: 41.56em;
			height: 7.65em;
			position: absolute;
			bottom: 9.5em;
			left: 50%;
			transform: translateX(-50%);
			background-color: black;
			span {
				color: white;
				font-size: 4.55em;
				font-weight: bold;
				font-family: 'Noto Sans', sans-serif;
			}
		}
	}
	.member-benefits-popup {
		position: relative;
		width: 100em;
		height: 90svh;
		display: flex;
		flex-direction: column;
		overscroll-behavior: contain;
		padding-bottom: 4rem;
		background-color: white;
		border-radius: 3.125em 3.125em 0 0;
    overflow: hidden;
		.top-bar {
			width: 100%;
			height: 13.65em;
			display: flex;
			align-items: center;
			border-bottom: 0.32em solid #D3D3D3;
			padding: 2em 2.5em 0;
			.tier-icon {
				width: 7.8em;
				height: calc(7.8em * 7 / 8);
			}
			label {
				position: absolute;
				left: 50%;
				transform: translate(-50%);
				color: black;
				font-size: 4.687em;
				font-weight: bold;
				line-height: 1;
			}
			.close-btn {
				width: 5.1em;
				height: 5.1em;
				padding: 1em;
				margin-top: -1em;
				margin-left: auto;
				margin-right: 0.4em;
			}
		}
		.content-container {
			position: relative;
			flex: 1;
			min-height: calc(90svh - 13.65em - 4rem);
			.content {
				width: 100%;
				height: 100%;
				overflow: auto;
			}
			.shadow {
				position: sticky;
				width: 100%;
				height: 15px;
				bottom: 0;
				box-shadow: inset 0 -10px 15px -10px rgba(0, 0, 0, 0.25);
			}
			img {
				padding: 0;
				padding-bottom: 2em;
			}
		}
	}
}