@keyframes scaleDown {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(0.8);
  }
  25% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.scale-down {
  animation: scaleDown 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(0deg);
  }
  5% {
    transform: scale(1.1) rotate(60deg);
  }
  10% {
    transform: scale(1.1) rotate(120deg);
  }
  15% {
    transform: scale(1.1) rotate(180deg);
  }
  20% {
    transform: scale(1.1) rotate(240deg);
  }
  25% {
    transform: scale(1.1) rotate(300deg);
  }
  30% {
    transform: scale(1) rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotating-box {
  animation: rotate 4s linear infinite;
}

@keyframes fadeOut {
  0% {
    line-height: 1;
    opacity: 1;
  }
  5% {
    line-height: 0;
    opacity: 0;
  }
  25% {
    line-height: 0;
    opacity: 0;
  }
  30% {
    line-height: 1;
    opacity: 1;
  }
  100% {
    line-height: 1;
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 4s linear infinite;
}

