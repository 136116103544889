.cHomePage
    padding: 0
    .swiper
        width: 100%
    .swiper-slide
        text-align: center
        width: 100svw
        height: 100%
    .swiper-slide
        img
        display: block
        width: 100%
        height: 100%
        object-fit: cover
    .triangle-right
        width: 0
        height: 0
        border-top: 1em solid transparent
        border-bottom: 1em solid transparent
        border-left: 2em solid white
        margin-left: 1em
        &.black
            border-left: 2em solid black

    /* ProfileCardCarousel Component*/
    .profile-card-carsousel
        position: relative
        font-size: 1svw
        width: 100%
        margin-bottom: 2em
        margin-top: 10.2em
        .carousel
            width: 100%
            padding: 2em 0
        .swiper-slide
            text-align: center
            width: 100%
            height: 100%


    /* ProfileCard Component*/
    .profile-card
        position: relative
        left: 50%
        transform: translateX(-50%)
        width: 89.6em
        height: 48em
        display: flex
        flex-direction: column
        border-radius: 1.05em
        overflow: hidden
        box-shadow: 0.6em 0.6em 1.21em rgba(0, 0, 0, 0.25)
        font-size: 1svw
        .profile-logo
            position: relative
            width: 100%
            img
                width: 9.8em
                margin: 3em
        .qr-button
            position: absolute
            top: 0
            right: 0
            width: 8.28em
            height: 8.28em
            margin: 2.8em
            cursor: pointer
            img
                width: 100%
    .profile-background
        position: absolute
        top: 0
        left: 0
        width: 100%
    .user-profile
        display: flex
        width: 100%
        min-width: 0
        font-size: 1em
        font-weight: bold
        .comp-user-avatar
            user-select: none
            overflow: hidden
            border-radius: 50%
            width: 13.75em
            height: 13.75em
            margin: 0.3em 2.6em 0.3em 3.2em
            outline: 0.65em solid black
            img
                height: 100%
                width: 100%
                object-fit: cover
                text-align: center
                color: transparent
                border-radius: 50%
        .profile-info
            display: flex
            flex-direction: column
            .vip-grade
                display: flex
                align-items: flex-end
                margin-top: 2.5em
                img
                    width: 6em
                    height: calc(6em * 7 / 8)
                    object-fit: cover
                    text-align: center
                    margin-right: 1.6em
                label
                    font-size: 3.66em
                    font-weight: bold
                    color: black
                    margin-right: 0.382em
                    line-height: 1
                span
                    font-size: 3em
                    font-weight: 400
                    color: #767676
                    line-height: 1
            .user-name
                text-align: left
                margin-top: 1.5em
                padding-left: 1em
                line-height: 1
                display: inherit
                label
                    text-align: left
                    font-size: 4em
                    font-weight: 500
                    color: black
                    line-height: 1
    .member-point
        display: flex
        margin-top: 4em
        padding: 0 3em
        width: 100%
        min-height: 5.28em
        img
            width: 3.9em
            height: 3.9em
            object-fit: cover
            text-align: center
            margin-right: 1.5em
        label
            font-size: 3.43em
            font-weight: bold
            color: black
        button
            display: flex
            justify-content: center
            align-items: center
            margin-top: -0.5em
            margin-left: auto
            width: 24.68em
            height: 5.78em
            background: black
            border-radius: 1.05em
            span
                color: white
                font-size: 3em
                line-height: 1
    .progress-bar
        width: 94%
        margin: 0 2.8%
        margin-top: 2.2em
        .member-tier-info
            min-width: 0
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            text-align: center
            margin-top: 2.2em
            label
                font-size: 3em
                font-weight: 500
                color: #9A9A9A
                line-height: 1
    .bottom-bar
        width: 100%
        height: 3.593em
        background: #185449
        margin-top: auto

    /* ProfileCardMask Component*/
    .profile-card-mask
        position: absolute
        z-index: 10
        top: 0
        left: 0
        width: calc( 100% + 2px )
        height: calc( 100% + 2px )
        background: rgba(0, 0, 0, 0.5)
        font-size: 1em
        label
            position: absolute
            top: 56.5%
            left: 3%
            font-size: 4.53em
            font-weight: bold
            color: white
            text-align: left
        button
            display: flex
            justify-content: center
            align-items: center
            position: absolute
            top: 58.1%
            right: 3.8%
            width: 24.68em
            height: 5.78em
            background: white
            border-radius: 1.05em
            span
                color: black
                font-size: 3em
                line-height: 1

    /* StoreConsultant Component*/
    .store-consultant
        display: flex
        flex-direction: column
        position: relative
        width: 100%
        font-size: 1svw
        margin: 0
        .top-bar
            position: relative
            display: flex
            width: 100%
            line-height: 1
            padding-top: 1em
            padding-left: 5.5em
            padding-right: 4em
            margin-bottom: 3em
            label
                font-size: 4.375em
                font-weight: 500
                color: black
            button
                margin-left: auto
                span
                    font-size: 3.125em
                    font-weight: 500
                    color: #9A9A9A
                .border-bottom
                    border-bottom: 1px solid #9A9A9A
        .unbound 
            width: 100%
            padding: 0 5.5em
            margin: auto
            label
                display: block
                font-size: 3.9em
                font-weight: bold
                color: black
                text-align: center
        .consultant-list
            display: flex
            position: relative
            flex: 1
            width: 100%
            overflow-x: auto
            padding: 0 4.5em
        .consultant
            display: flex
            flex: 0 0 auto
            position: relative
            width: 50%
            min-height: 19.126em
            .consultant-img
                width: 14.218em
                height: 14.218em
                border-radius: 1.1em
                overflow: hidden
                margin: 1.74em 0.8em
            .consultant-info
                display: flex
                flex-direction: column
                flex: 1 1
                padding: 4.5em 1.5em
                .title
                    font-size: 3.125em
                    font-weight: 500
                    color: black
                    margin-bottom: 1em
                    line-height: 1
                .name
                    font-size: 3.9em
                    font-weight: bold
                    color: black
                    line-height: 1.2